
.contact-form {
    width: 100%;
        
    ul {
      padding: 0;
      margin-top: 20px;
      
      li {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: 10px;
        opacity: 0;
        overflow: hidden;
        display: block;
        clear: both;
        position: relative;
        animation: fadeInUp 2s 2s;
        animation-fill-mode: forwards;
      }
  
      li.half {
        width: 49%;
        margin-left: 2%;
        float: left;
        clear: none;
  
        &:first-child {
          margin-left: 0;
        }
      }
    }
  
    input[type='text'],
    input[type='email'] {
      width: 100%;
      border: 1px solid lightsalmon;
      background: #1c1f4e;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 0 20px;
      box-sizing: border-box;
      border-radius: 4px;
    }
  
    textarea {
      width: 100%;
      border: 1px solid lightsalmon;
      background: #1c1f4e;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 20px;
      box-sizing: border-box;
      min-height: 150px;
      border-radius: 4px;
    }
  
    .flat-button {
      color: #f16d23;
      font-size: 11px;
      letter-spacing: 3px;
      text-decoration: none;
      padding: 8px 10px;
      border: 1px solid #f16d23;
      float: left;
      border-radius: 4px;
      background: 0 0;
      text-transform: uppercase;
      float: right;
      text-align: center;
      margin-right: 10px;
      border-radius: 4px;

      &:hover {
        background: #f16d23;
        color: #2b253f;
     }
    }
  }

  .info-map {

    position: absolute;
    background-color: #1c1f4e;
    top: 12px;
    right: 16%;
    z-index: 5;
    width: 267px;
    color: #fff;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;
    border-radius: 4px;
    
  .text-map{

    margin-left: 15px;
      span {
      font-size: 15px;
      display: block;
      padding-top: 15px;
      color: #f16d23;
      
    }
  }
  
  }
  .map-wrap {
    background: rgb(25, 24, 32, 0.1);
    float: right;
    margin: 0;
    margin-right: 20px;
    width: 40%;
    height: 100%;
    border-radius: 4px;
  }

  .leaflet-container {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 0;
    border-radius: 4px;
    animation: backInRight 1s 1.5s;
    animation-fill-mode: forwards;
  }
 
  
@media screen and (max-width: 1200px) {

  .map-wrap {
    float: none;
    width: 100%;
    height: 135px;
    margin-top: -15px;
  }
  
  .info-map {
    display: none;
  }

  
.contact-form {
  width: 100%;
      
  ul {
    padding: 0;
    margin-top: 10px;
    
    li {
      margin-bottom: 10px;
    }

   
  }

  input[type='text'],
  input[type='email'] {
    
    height: 25px;
    font-size: 14px;
    color: #fff;
    box-sizing: border-box;
    border-radius: 4px;
  }

  textarea {
    width: 100%;
    border: 1px solid lightsalmon;
    background: #1c1f4e;
    height: 25px;
    font-size: 14px;
    color: #fff;
    box-sizing: border-box;
    
    border-radius: 4px;
  }

  .flat-button {
    color: #f16d23;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 4px 5px;
    border: 1px solid #f16d23;
    float: left;
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    float: right;
    text-align: center;
    margin-right: 10px;
    border-radius: 4px;

    &:hover {
      background: #f16d23;
      color: #2b253f;
   }
  }
}
}
  

  
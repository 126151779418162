.stage-cube-cont {
    width: 50%;
    height: 100%;
    top: 0;
    padding-top: 18%;
    margin-left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;
}

.cube-spiner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 50px);

    div {
        position: absolute;
        width: 200px;
        height: 200px;
        border: 1px solid #ccc;
        background: rgba(255, 255, 255, 0.3);
        text-align: center;
        font-size: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 20px 0px lightblue;
      }

      .face1 {
        transform: translateZ(100px);
        color: #54d4f4;
      }
      .face2 {
        transform: rotateY(90deg) translateZ(100px);
        color: #f06529;
      }
      .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
        color: #3aad61;
      }
      .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
        color: #efd81d;
      }
      .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
        color: #dd0031;
      }
      .face6 {
        transform: rotateX(-90deg) translateZ(100px);
        color: #28a4d9;
      }
}

@keyframes spincube {
    from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  33% {
    transform: rotateY(-90deg) rotateX(90deg);
  }
  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }
  83% {
    transform: rotateX(90deg);
  }
}

@media screen and (max-width: 1200px) {
   .stage-cube-cont {
    position: initial;
    width: 75%;
    height: 0;
    margin-top: -50px;
    overflow: visible;
   }
   
}
.home-page {
    .text-zone {
        position: absolute;
        left:20%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-width: 90%;
    }

    h1 {
        color: #fff;
        font-size: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;

        &::before {
           content: '<h1>';
           font-family: 'La Belle Aurore';
           color: #f16d23;
           position: absolute;
           font-size: 20px;
           margin-top: -18px;
           left: -25px;
           opacity: 0.6;
        }
        
        &::after {
            content: '</h1>';
            font-family: 'La Belle Aurore';
            color: #f16d23;
            position: absolute;
            margin-top: 65px;
            font-size: 18px;
            margin-left: 45px;
            animation: fadeIn is 0.8s 1.8s backwards;
            opacity: 0.6;
         }


        img{
            width: 48px;
            margin-left: 20px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
            
        }
    }

    h2 {
        color: #8d8d8d;
        margin: 0px;
        padding-top: 10px;
        font-weight: 400;
        font-size: 12px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: #f16d23;
        font-size: 14px;
        font-weight: 400;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px;
        border: 1px solid #f16d23;
        margin: 25px;
        float: left;
        animation: fadeIn 1.6s 2s backwards;
        white-space: nowrap;
        border-radius: 4px;

        &:hover {
            background: #f16d23;
            color: #2b253f;
        }
        
    }

}

@media screen and (max-width: 1200px) {
    .tags,
    .home-page h1::before,
    .home-page h1::after {
    display: none;
    }
    .home-page {       
        
        .text-zone {
            position: initial;
            width: 100%;
            transform: none;
            padding: 50px;
            box-sizing: border-box;
        }
        .flat-button{
            margin-top: 45px;
            margin-left: 70%;
        }

        .logo-container{
            position: absolute;
            width: 250px;
            height: auto;
            opacity: 0.5;
        }
    }
    
}
.page {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .top-tags {
    bottom: auto;
    top: 35px;
  }
  
  .tags {
    color: #e16d23;
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 18px;
    font-family: 'La Belle Aurore', cursive;
  }
  
  .bottom-tag-html {
    margin-left: -20px;
  }

  .container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top:5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;

  }

  .about-page,
  .contact-page {
    .text-zone {
      position: absolute;
      left: 16%;
      top: 50%;
      transform: translateY(-50%);
      width: 35%;
      vertical-align: middle;
      display: table-cell;

      h1 {
        font-size: 68px;
        font-family: Coolvetica;
        color:#e16d23;
        font-weight: 400;
        margin-top: 0;
        position: relative;
        margin-bottom: 40px;
        

        &::before {
          content: '<h1>';
          font-family: 'La Belle Aurore';
          font-size: 18px;
          position: absolute;
          margin-top:  -10px;
          left: -10px;
          opacity: 0.6;
          line-height: 18px;
        }

        &::after {
          content: '</h1>';
          font-family: 'La Belle Aurore';
          font-size: 18px;
          position: absolute;
          left: -30px;
          bottom: -20px;
          margin-left: 20px;
          opacity: 0.6;
          line-height: 18px;
        }
      }

        p {
          font-size: 14px;
          color: #fff;
          font-family: sans-serif;
          font-weight: 300;
          min-width: fit-content;
          animation: pulse 1s;

          &:nth-of-type(1) {
            animation-delay: 1s;
          }
          &:nth-of-type(2) {
            animation-delay: 1.2s;
          }
          &:nth-of-type(3) {
            animation-delay: 1.4s;
          }
        }

        .text-animate-hover {
          &:hover {
          color: #fff;
          }
        }
    }
  }

  @media screen and (max-width: 1200px) {
    .page {
      position: initial;
      
    }
   
    .about-page,
    .contact-page{
      
      position: initial;
      height: auto;

      &.about-page,
      &.contact-page{

        .text-zone {
          position: initial;
          transform: none;
          width: 100%;
          display: block;
          padding: 10px;
          box-sizing: border-box;
          h1 {
            font-size: 35px;
            margin-top: -15px;
            margin-left: 80px;
          
            &::before,
            &::after {
            display: none;
            }
          }
          p {
            font-size: 10px;
            font-weight: 400;
            margin-top: -8px;
          }
        }
      }
    }
  }
  
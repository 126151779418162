.logo-container {
    z-index: -1;
    width: 400px;
    height: 600px;
    position: absolute;
    top: 20%;
    right: 15%;
    bottom: 0;
    left: auto;
    margin: auto;
    opacity: 0.7; 
    transform: rotateZ(30deg);
    
    
}

   
.biglogo{
    width: 100%;
    height: auto;
    bottom: 0;
    animation: backInLeft  3s 0.8s;    
    animation-fill-mode: forwards;

    .svg-container {
        stroke:#f16d23;
        stroke-width: 1;
      }    
    }

    

    .solid-logo {
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
        width: 100%;
        z-index: 1;
        animation: backInRight 3s 0.8s;
        animation-fill-mode: forwards;  
    }
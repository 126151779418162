.nav-bar {
    background-color: #010322;
    width: 90px;
    height: 96%;
    position: absolute;
    top: 2%;
    bottom: 2%;
    left: 10px;
    z-index: 3;
    min-height: 500px;
    border-radius: 18px;

    .logo {
      margin-top: 10px;
      display: block;
      padding: 2px 0;

      img {
        display: block;
        margin: auto;
        width: 40px;
        height: auto;
        
        &.sub-logo {
          width: 62px;
        }
      }
   }

   nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -120px;
    width: 100%;

    a {
      font-size: 22px;
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }
  
      &:hover {
        color: #f16d23;
  
        svg { 
          opacity: 0;
        }

      &:after {
        opacity: 1;
      }
    }
      &:after {
        content: '';
        font-size: 12px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        transition: all 0.3 ease-out;
      }

      &:first-child{
        &::after{
          content: 'HOME';
        }
       }
      }

      a.about-link{
        &:after{
          content: 'ABOUT';
        }
      }

      a.contact-link{
        &:after{
          content: 'CONTACT';
        }
      }
      
      a.active {
        svg {
          color: #f16d23;
        }
      }
    }

    ul {
      position: absolute;
      bottom: 30px;
      width: 100%;
      display: block;
      padding: 0;
      list-style: none;
      text-align: center;
      margin: 0;

      li {
        a{
          padding: 7px 0;
          display: block;
          font-size: 20px;
          line-height: 22px;

          &:hover svg {
            color:#f16d23
          }
        }
      }

    }

    .hamburguer-icon,
    .close-icon  {
      display: none;
     }
  }

  @media screen and (max-width: 1200px) {
  
    .nav-bar {
     background: transparent;
     position: initial;
     height: auto;
     min-height: auto;
 
     ul, nav {
       display: none;
     }
 
     nav {
       width: 100%;
       height: 200px;
       background: #010322;
       top: 0;
       left: 0;
       position: fixed;
       margin: 0;
       z-index: 2;

       a {
        display: flex;
        justify-content: center;
        align-items: center;
      }
  
      a:after {
        opacity: 1;
        position: initial;
        width: auto;
        margin-left: 10px;
      }

      a svg {
        opacity: 1 !important;
       }

       &.mobile-show {
        display: block;
       }
     }

     .hamburguer-icon,
     .close-icon {
      display: block;
      position: absolute;
      top: 20px;
      right: 15px;
     }
    }
   }


